<template>
  <div class="email-campaign">
    <div class="email-step1 active">
      <div class="step1-area">
        <div class="email-deatils">
          <h3>Add Template</h3>
          <form action="">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="title">Template Title *</label>
                <input
                  v-model="temp_.title"
                  type="text"
                  class="form-control"
                  id="title"
                  required
                  placeholder="Enter Title"
                />
                <div
                  class="validate-error-message"
                  v-if="$vd.temp_.title.$hasError === true"
                >
                  {{ $vd.temp_.$errors.title[0] }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label for="title">Subject*</label>
                <input
                  v-model="temp_.subject"
                  type="text"
                  class="form-control"
                  id="subject"
                  required
                  placeholder="Enter Subject"
                />
                <div
                  class="validate-error-message"
                  v-if="$vd.temp_.subject.$hasError === true"
                >
                  {{ $vd.temp_.$errors.subject[0] }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label for="fromName">Content *</label>
                <textarea
                  class="form-control"
                  id="alltext"
                  required
                  v-model="temp_.body"
                ></textarea>

                <!-- <ckeditor v-model="temp_.body" @ready="onEditorReady"  id="editor1" :config="editorConfig"></ckeditor> -->
                <div
                  class="validate-error-message"
                  v-if="$vd.temp_.body.$hasError === true"
                >
                  {{ $vd.temp_.$errors.body[0] }}
                </div>
                <span for="title" style="font-weight: 700; margin-top: 5px"
                  >Variables:
                </span>
              </div>

              <a
                class="add-btn"
                v-on:click.stop.prevent="addText('%CUSTOMER_NAME%', $event)"
                >%CUSTOMER_NAME%</a
              >
              <a
                class="add-btn"
                v-on:click.stop.prevent="addText('%DEALER_NAME%', $event)"
                >%DEALER_NAME%</a
              >
              <a
                class="add-btn"
                v-on:click.stop.prevent="addText('%VEHICLE_MAKE%', $event)"
                >%VEHICLE_MAKE%</a
              >
              <a
                class="add-btn"
                v-on:click.stop.prevent="addText('%VEHICLE_YEAR%', $event)"
                >%VEHICLE_YEAR%</a
              >
              <a
                class="add-btn"
                v-on:click.stop.prevent="addText('%VEHICLE_TRIM%', $event)"
                >%VEHICLE_TRIM%</a
              >
              <a
                class="add-btn"
                v-on:click.stop.prevent="addText('%SALES_PERSON%', $event)"
                >%SALES_PERSON%</a
              >
              <a
                class="add-btn"
                v-on:click.stop.prevent="addText('%BDC%', $event)"
                >%BDC%</a
              >
            </div>
            <div class="row"></div>
          </form>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12">
        <router-link
          to="/templates"
          class="add-btn pull-left pr-5 pl-5"
          href="#"
          >Cancel</router-link
        >
        <a
          class="rem-btn pull-right pr-5 pl-5"
          v-on:click.stop.prevent="addTemp()"
          href="#"
          >Add</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueDaval from "vue-daval";
export default {
  name: "addNewTemplate",
  mixins: [VueDaval],
  vdRules: {
    temp_: {
      title: { required: true },
      body: { required: true },
      subject: { required: true },
    },
  },
  created() {
    //this.getCampaignsList()
    this.getEmailSignature();
  },
  data() {
    return {
      temp_: { title: "", body: "", subject: "" },
    };
  },

  methods: {
    onEditorReady(CKEDITOR) {
      this.editor = CKEDITOR;
    },
    addText(val, event) {
      console.log(event);
      // var targ = event.target || event.srcElement;
      // document.getElementById("alltext").value +=
      //   targ.textContent || targ.innerText;

      // console.log(val);
      this.temp_.body += val;
    },
    getEmailSignature() {
      axios
        .get("get/email/signature", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            window.tinymce.activeEditor.setContent(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addTemp() {
      if (this.temp_.title != "" && this.temp_.body != "") {
        axios
          .post("add_smstemplate", this.temp_, {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          })
          .then((res) => {
            if (res.data.success) {
              this.$router.push("/sms-templates");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$toast.open({
          position: "top-right",
          message: "Some fields are missing",
          type: "info",
        });
      }
    },
  },
};
</script>
<style scoped>
.add-btn {
  margin-right: 14px;
  margin-top: 5px;
}
</style>
